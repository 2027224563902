import axios from 'axios'
import store from '@/store'

axios.interceptors.request.use(
  cfg => {
    console.log('axios.interceptors.request');
    store.commit('setIsLoading', true);
    return cfg;
  },
  err =>{
    console.log('axios.interceptors.request.err');
    store.commit('setIsLoading', false);
    return Promise.reject(err);
  }
)

axios.interceptors.response.use(
  res => {
    console.log('axios.interceptors.response');
    store.commit('setIsLoading', false);
    return res;
  },
  err => {
    console.log('axios.interceptors.response.err');
    store.commit('setIsLoading', false);
    return Promise.reject(err);
  }
)

export default axios
