<template>
  <div>
    <b-container class="mt-4">
      <b-row>
        <b-col coll="12" lg="12">
          <boton-lista-cupones></boton-lista-cupones>
        </b-col>
      </b-row>
      <b-row>
        <b-col  coll="7" lg="7">
          <b-card class="mt-4 scroll">
            <template #header>
              <b-row>
                <b-col cols="8" class="text-left">
                  Cupones pendientes de aprobación
                </b-col>
                <b-col cols="4" class="text-right">
                  <router-link to="/informar-problema" v-slot="{href, route, navigate}"  >
                    <b-button size="sm" :href="href" variant="light" @click="navigate">
                        {{ getIsMobile ?  "Informar un problema " : route.name }}
                        <b-icon icon="flag" aria-hidden="true"></b-icon>
                    </b-button>
                  </router-link>
                </b-col>
              </b-row>
            </template>
            <table-cupones :columnas="['ConceptoString','Id','TipoPagoString','FechaInforme','ImporteInformado' ]" 
            :selectable="false" 
            selectmode="multi" 
            v-if="this.alumno" 
            :listacupones="this.alumno.Cupones.filter(c => c.Estado === 'Pendiente')"
            rowClass="table-warning">
            </table-cupones>
          </b-card>
        </b-col>
        <b-col coll="5" lg="5">
          <card-alumno v-if="this.alumno" :alumno="this.alumno">
          </card-alumno>
          <card-detalle-pago>
          </card-detalle-pago>
        </b-col>
      </b-row>
    </b-container>
    <b-modal size="lg" id="modal-formapago" hide-header hide-footer>
      <div class="d-block text-center">
        <card-forma-pago></card-forma-pago>
        <b-button @click="$bvModal.hide('modal-detallepago')">Cerrar </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import TableCupones from '@/components/TableCupones.vue';
import CardFormaPago from '@/components/cards/CardFormaPago.vue';
import CardDetallePago from '@/components/cards/CardDetallePago.vue';
import CardAlumno from '@/components/cards/CardAlumno.vue';
import BotonListaCupones from '@/components/BotonListaCupones.vue';

const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});
export default {
  name: "VerCuponesPendientes",
  props: {
    titulo: String
  },
  watch: {
    alumno(newval, oldval) {
      console.log('alumno old', oldval);
      console.log('alumno new', newval);
    },
    estadotransaccion(newval, oldval){
      console.log('alumno old', oldval);
      console.log('alumno new', newval);
    }
  },
  computed: {
    ...mapFieldsAlumno(["alumno","Dni", "mensajes"]),
    ...mapFieldsPago(['formapago','importe','cuponesseleccionados','estadotransaccion']),
    displayDebitoDirecto: function() {
      return this.alumno.DebitoDirecto
        ? this.mensajeDebito
        : this.mensajeNoDebito;
    },
    listacupones: function(){
      var lista = this.alumno ? this.alumno.Cupones.map(({Id, Estado, Importe}) => ({Id, Estado, Importe})) : [];
      return lista;
    },
  },
  components: {
    TableCupones,
    CardFormaPago,
    CardDetallePago,
    CardAlumno,
    BotonListaCupones
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>
<style>

</style>