<template>
  <div>
    <b-card header-tag="header" class="mt-4">
      <h4>
        <img :src="logo" /> Debito Directo
        <router-link to="/formapago" v-slot="{href, route, navigate}"  >
            <b-button :href="href" variant="light"  @click="navigate" class='mr-2'>
              Cambiar Forma de Pago
            </b-button>
        </router-link>
      </h4>
      <hr>
      <slot></slot> 
        <b-card-text>

          <p>Descargue el formulario, y preséntelo en el colegio {{this.nivelenseñanza.DisplayUnidadEducativaNivelSinCuise}}. Allí podrá optar en qué fecha desea que opere el débito <b>(las alternativas son el 1, 10 o 20 de cada mes)</b></p>

          <div class="text-center">
            <b-button  href="https://cdn.saed.digital/Uploads/Documentos/adhesiondebitodirecto.pdf" target="_blank">
                <b-icon icon="download" aria-hidden="true"></b-icon> Descargar Formulario de adhesión
            </b-button>
          </div>
        </b-card-text>
    </b-card>
  </div>
</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});
export default {
  name: "CardDebitoDirecto",
  props: {
      logo: String
  },
  watch: {

  },
  computed: {
    ...mapFieldsPago(['formapago','importe','cuponesseleccionados', "nivelenseñanza"]),
    ...mapFieldsAlumno(['alumno', 'idNivel']),
  },
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>
