<template>
  <div>
    <b-card bg-variant="light">
      <b-row>
        <b-col lg="12">
          <validation-observer ref="observer" v-slot="{ handleSubmit, reset }">
            <b-form id="informarProblemaForm" @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="reset">

              <validation-provider name="Nombre" :rules="{required: true}" v-slot="validationContext">
                <b-form-group id="name-group" label="Nombre" label-for="name">
                  <b-form-input
                    v-model="name"
                    placeholder="Ingrese su nombre"
                    :state="checkForm(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback id="name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider name="email" :rules="{required: true, email}" v-slot="validationContext">
                <b-form-group id="email-group" label="Correo electrónico" label-for="email">
                  <b-form-input
                    v-model="email"
                    placeholder="Ingrese su correo electrónico"
                    :state="checkForm(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback id="email-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <!-- FIX: la validacion no funciona con el componente multiselect -->
              <validation-provider name="Medio de pago" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group id="subject-group" label="Medio de pago" label-for="subject">
                <multiselect
                  id="subject"
                  v-model="subject"
                  :options='["Pago Fácil", "Caja Municipal", "Depósito", "Transferencia", "LINK Pagos", "Débito Directo"].sort()'
                  placeholder="Seleccione el medio de pago con el que tuvo problemas"
                  selectLabel="Enter para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Enter para remover"
                >
                  <span slot="noResult">Su búsqueda no arrojó ningún resultado.</span>
                </multiselect>
                <b-form-invalid-feedback id="subject-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

              <validation-provider name="detalle" :rules="{required: true}" v-slot="validationContext">
                <b-form-group id="detail-group" label="Detalle del problema" label-for="detail">
                  <b-form-textarea
                      v-model="detail"
                      placeholder="Describa el problema de tuvo"
                      :state="checkForm(validationContext)"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="detail-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider name="comprobante" :rules="{ required: false, ext: ['jpg', 'png', 'pdf'], size: 3072 }" v-slot="validationContext">
                <b-form-group id="inputComprobante-group" label="Comprobante (opcional)" label-for="inputComprobante">
                  <b-form-file
                    class="mb-2"
                    browse-text="Adjuntar 📎"
                    v-model="inputComprobante"
                    placeholder="Archivo jpg, png, pdf"
                    drop-placeholder="Suelte el archivo aquí..."
                    :state="checkForm(validationContext)"
                  ></b-form-file>
                  <!-- <p v-if="inputComprobante">{{inputComprobante.name}}</p> -->
                  <b-button class="btn-sm" v-if="inputComprobante" @click="inputComprobante = null">Quitar archivo </b-button>
                  <b-form-invalid-feedback id="inputComprobante-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-button class="mt-2" type="submit" variant="success">Enviar</b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import api from "@/api";
import axios from "axios";
import swalerts from "@/helpers/swalerts";
import errorhandler from "@/helpers/errorhandler";
// import moment from 'moment';
import { createHelpers } from "vuex-map-fields";
import { mapGetters } from "vuex";

const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});

export default {
  name: "FormInformarProblema",
  mixins: [errorhandler, swalerts],
  components: {
  },
  computed: {
    ...mapGetters(["getSiteKey"]),
    ...mapGetters("AlumnoModule", ["alumnoIsValid"]),
    ...mapFieldsAlumno(["Dni", "mensajes", "alumno"]),
  },
  data() {
    let name;
    let email;
    let subject;
    let detail;
    let inputComprobante;
    return {
      name,
      email,
      subject,
      detail,
      inputComprobante
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    resetForm() {
      document.getElementById('informarProblemaForm').reset();
      this.name = null;
      this.email = null;
      this.subject = null;
      this.detail = null;
      this.inputComprobante = null;
    },

    readUploadedFileAsText(inputFile) {
      const tempFR = new FileReader();

      return new Promise((resolve, reject) => {
        tempFR.onerror = () => {
          tempFR.abort();
          reject(new DOMException("Problem parsing input file."));
        };

        tempFR.onload = () => {
          resolve(tempFR.result);
        };

        try {
          tempFR.readAsDataURL(inputFile);
        } catch (error) {
          console.error(error);
        }

      });
    },

    async onSubmit() {

      let app = this;
      let fileData;

      let headers = {
        "headers" : {
          "X-API-Key": `${api.apikeyosticket}`,
        }
      };

      let data = {
        "alert": true,
        "name": this.name,
        "email": this.email,
        "topicId": "2",
        "subject": `${this.subject} ${this.alumno.DisplayNivelEnseñanzaSinCuise} ${this.alumno.DisplayDniNombre}`,
        "message": `data:text/html;charset=utf-8,
          <br><b>Nombre</b>: ${this.name}
          <br><b>Alumno</b>: ${this.alumno.DisplayDniNombre}
          <br><b>Medio de pago</b>: ${this.subject}
          <br><b>Colegio</b>: ${this.alumno.DisplayNivelEnseñanzaSinCuise}
          <br><b>MENSAJE</b>:<br>${this.detail}`,
      };

      // Si se adjuntó un archivo, incluirlo en el JSON
      if (this.inputComprobante) {
        fileData = await this.readUploadedFileAsText(this.inputComprobante);
        data.attachments = [{
          [this.inputComprobante.name]: fileData,
        }];
      }

      // console.log(data);

      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
      });

      axios.post(`${api.apiosticket}`, data, headers)
      .then(function (res) {
        console.log(res);
        app.showSuccess(
            "Le responderemos a la brevedad por correo electrónico",
            `El formulario fue enviado\nNúmero de seguimiento #${res.data}`
          );
        app.resetForm();
        app.$router.push('buscaralumno');
      })
      .catch(function (error) {
        // console.log(error.toJSON(), error.response);
        console.error(error);
        let msg = "";
        if (error && error.response && error.response.status == 400) {
          msg = "Verifique los datos ingresados"
        }
        app.showError(`Error al enviar el formulario. ${msg}`);
      })
      .then(function () {
        loader.hide();
      })
    },

    checkForm: function({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }

  },
  created() {
  },
};
</script>

<style  scoped>
dt {
  float: left;
  clear: left;
  margin-right: 5px;
  font-weight: bold;
}

dd {
  margin-left: 0px;
}
</style>