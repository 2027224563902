// file-upload.service.js

import axios from "axios";
import api from "@/api";


export default {
    upload(formData) {
        const url = `${api.fileupload}/upload`;
        return axios.post(url, formData)
            // get data
            .then(x => {
                console.log('end file upload', x.data);
                return x;
            });
    }
};