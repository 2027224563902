<template>
  <div>
    <b-card bg-variant="light" header="Buscar Alumno" header-tag="header" class="mt-4">
      <b-row>
        <b-col lg="12">
          <validation-provider
            name="establecimiento"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group id="selectEstablecimientos-group" label="Elegir colegio" label-for="selectEstablecimientos">
              <!-- <label class="typo__label">Elegir colegio {{selected?selected.DisplayUnidadEducativaNivelSinCuise:''}}</label> -->
              <multiselect
                v-model="nivelenseñanza"
                id="selectEstablecimientos"
                label="DisplayUnidadEducativaNivelSinCuise"
                track-by="Id"
                placeholder="Escriba el nombre del colegio"
                :options="listacolegios"
                :searchable="true"
                :clear-on-select="false"
                @select="onSelect"
                :close-on-select="true"
                :show-no-results="true"
                selectLabel="Enter para seleccionar"
                selectedLabel="Seleccionado"
                deselectLabel="Enter para remover"
                noOptions="Sin resultados"
                :allow-empty="true"
                :state="checkForm(validationContext)"
              >
                <span slot="noResult">Su búsqueda no arrojó ningún resultado</span>
              </multiselect>
              <b-form-invalid-feedback id="selectEstablecimientos-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider>
            <b-form-group label="Ciclo lectivo">
              <b-form-radio-group v-model="ciclolectivo">
                <b-form-radio :value="añoAnterior">{{ añoAnterior }}</b-form-radio>
                <b-form-radio :value="añoActual">{{ añoActual }}</b-form-radio>
                <b-form-radio :value="añoSiguiente" v-if="añoSiguiente">{{ añoSiguiente }}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </validation-provider>

          <validation-provider
            name="dni"
            :rules="{ required: true, digits: 8 }"
            v-slot="validationContext"
          >
            <b-form-group id="inputDni-group" label="DNI del alumno" label-for="inputDni">
              <b-input id="inputDni" 
                @keyup.enter="formIsValid && recaptcha()"
                v-model="Dni" 
                @keypress="isNumber($event)"
                :state="checkForm(validationContext)"></b-input>
              <b-form-invalid-feedback id="inputCuentaOrigenApellido-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-button @click.once="recaptcha()" variant="info" type="submit" :disabled="!formIsValid" class="mr-2">Buscar</b-button>
          <b-button @click.once="cleanFormLocal" class="mr-2">Limpiar</b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import moment from 'moment';
import errorhandler from "@/helpers/errorhandler";

const { mapFields } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});

const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});

export default {
  name: "FrmBuscarAlumno",
  mixins: [errorhandler],
  components: {
  },
  computed: {
    ...mapGetters("AlumnoModule", ["alumnoIsValid"]),

    ...mapFields(["Dni", "mensajes", "alumno"]),
    ...mapFieldsPago(["nivelenseñanza","importe", "cuponesseleccionados", "tienematricula", "ciclolectivo"]),
    dniIsValid() {
      return !!this.Dni && this.Dni.length > 7 && this.Dni.length < 9;
    },
    formIsValid() {
      return !!this.dniIsValid && this.nivelenseñanza && this.ciclolectivo;
    },
  },

  data() {
    return {
      unidadadministrativa_id: process.env.VUE_APP_UA,
      añoAnterior: (new Date().getFullYear()) - 1,
      añoActual: new Date().getFullYear(),
      añoSiguiente: (new Date()).getMonth() >= 8 ? (new Date().getFullYear()) + 1 : null,
      listacolegios: []
    };
  },

  methods: {
    ...mapActions("AlumnoModule", ["findAlumno", "cleanAlumno"]),
    ...mapActions("CuponModule", { getListaCupones : "getLista"}),
    ...mapActions("NivelModule", ["getLista", "getEstablecimientos"]),
    //...mapActions(["loadSiteKey"]),
    onSelect (option) {
      console.log('onSelect',option);
    },
    getSelectEstablecimientos(){
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        //canCancel: true,
        //onCancel: this.onCancel,
      });
      this.getEstablecimientos(this.unidadadministrativa_id)
        .then((r) => {
          loader.hide();
          r.data.rows
            .forEach((element) => {
            this.listacolegios.push(element);
          });
        })
        .catch((e) => {
          this.errorHandler(e, loader);
          console.log(e);
        });
    },
    async getCaptcha(){
      var enableRecaptcha = (process.env.VUE_APP_ENABLE_RECAPTCHA === 'true');
      console.log('enableRecaptcha', enableRecaptcha);
      var token = '';
      if(enableRecaptcha){
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded();
  
        // Execute reCAPTCHA with action "buscar".
        token = await this.$recaptcha('buscar');
        return token;
      }
      return '';
    },
    async recaptcha() {
      this.getCaptcha().then(token => {
        this.findAlumnoLocal(token);
      });
    },
    findAlumnoLocal(recaptchatoken) {
      this.cuponesseleccionados = [];
      this.importe = 0;
      // var desde = moment().subtract(1, 'year').startOf('year');
      var desde = moment().year(this.ciclolectivo - 1).month(8).startOf('month');
      var hasta = moment().add('days', 1);
      if (this.formIsValid) {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          //canCancel: true,
          //onCancel: this.onCancel,
        });
        this.findAlumno({
          nivelenseñanza_id: this.nivelenseñanza.Id,
          // TODO: submit ciclolectivo
          ciclolectivo: this.ciclolectivo,
          dni: this.Dni,
          incluyecupones:true,
          //fechahasta: hasta.format("YYYY-MM-DD"),
          grecaptcha: recaptchatoken,
          appversion: process.env.VUE_APP_VERSION,
        })
          .then(alures => {
            console.log('alures', alures);
            this.getCaptcha().then(cph => {
              console.log('captcha', cph);
              this.getListaCupones({
                grecaptcha: cph,
                appversion: process.env.VUE_APP_VERSION,
                nivelenseñanza_id:this.nivelenseñanza.Id,
                unidadeducativa_id:this.nivelenseñanza.UnidadEducativa_Id,
                unidadadministrativa_id:this.nivelenseñanza.UnidadAdministrativa_Id,
                alumno_id: alures.Id,
                fechadesde: desde.format("YYYY-MM-DD"),
                fechahasta: hasta.format("YYYY-MM-DD"),
                sort: 0,
                order: 'desc',
                limit: 100
              }).then(cupres => {
                console.log('cupres', cupres);
                this.alumno.Cupones = cupres.data.rows;
                let añoAnterior = ((new Date().getFullYear()) - 1);
                let añoActual = ((new Date().getFullYear()));
                let añoSiguiente = ((new Date().getFullYear()) + 1);
                this.tienematricula = cupres.data.rows.find(item => (item.TipoConceptoCobro === 1 || item.TipoConceptoCobro === 8) && (item.PeriodoAño === añoAnterior || item.PeriodoAño === añoActual || item.PeriodoAño === añoSiguiente));
                console.log('tienematricula', this.tienematricula);
                loader.hide();
              }).catch(errcup => {
                this.errorHandler(errcup, loader);
                this.alumno = null;
                this.cuponesseleccionados = [];
                this.importe = 0;
              });
            });
          })
          .catch((erralu) => {
            this.errorHandler(erralu, loader);
            this.alumno = null;
            this.cuponesseleccionados = [];
            this.importe = 0;
          });
      }else{
        this.showError("Error al validar el formulario.");
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    cleanFormLocal() {
      this.ciclolectivo = this.añoActual;
      this.cleanAlumno();
      this.nivelenseñanza = {};
    },
    checkForm: function({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }
  },
  created() {
    //this.loadSiteKey();
    this.getSelectEstablecimientos();
  },
};
</script>

<style  scoped>
</style>