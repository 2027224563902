import { createHelpers } from "vuex-map-fields";
import TransaccionBancariaSvc from "../services/transaccionbancaria";

const { getTransaccionBancariaField, updateTransaccionBancariaField } = createHelpers({
    getterType: "getTransaccionBancariaField",
    mutationType: "updateTransaccionBancariaField"
});
export default {
    namespaced: true,
    state: {
        transaccionBancaria: null,
        tabla: {
            items: [],
            fields: [{ key: "id" }],
            rows: [],
            isBusy: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50]
        }
    },
    mutations: {
        updateTransaccionBancariaField,
    },
    actions: {
        getLista(ctx, query) {
            console.log('transaccionbancariamodule.getLista', ctx);
            return TransaccionBancariaSvc.getLista({
                search: query
            });
        },
        crear(ctx, data) {
            console.log("transaccionbancariamodule.crear", data);
            return TransaccionBancariaSvc.crear(data).then(r => {
                //ctx.commit("setAlumno", r.data.objeto);
                console.log('result crear', r.data.objeto);
                return r.data;
            });
        },
    },
    getters: {
        getTransaccionBancariaField
    }
};