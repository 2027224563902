<template>
  <div>
    <b-card header-tag="header" class="mt-4">
      <h4>
        <img :src="logo" />
        <router-link to="/formapago" v-slot="{href, route, navigate}"  >
            <b-button :href="href" variant="light"  @click="navigate" class='mr-2'>
              Cambiar Forma de Pago
            </b-button>
        </router-link>
      </h4>
      <hr>
      <slot></slot> 
      <p>En esta forma de pago se debe registrar el siguiente formulario para poder validar el pago dentro de 72 hs.</p>
      <form-deposito></form-deposito> 
    </b-card>
  </div>
</template>
<script>
import FormDeposito from '@/components/forms/FormDeposito.vue';

export default {
  name: "CardDeposito",
  props: {
      logo: String
  },
  watch: {

  },
  computed: {
  },
  components: {
    FormDeposito
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>
