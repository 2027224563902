<template>
  <div>
    <b-card header-tag="header" class="mt-4">
      <h4>
        <img :src="logo" /> Siro Roela
        <router-link to="/formapago" v-slot="{href, route, navigate}"  >
            <b-button :href="href" variant="light"  @click="navigate" class='mr-2'>
              Cambiar Forma de Pago
            </b-button>
        </router-link>
      </h4>
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://drive.google.com/file/d/1slRbedGL-QKQC94fd10HkMtGsvE8tpCq/preview"
        allowfullscreen
      ></b-embed>
    </b-card>
  </div>
</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField"
});
export default {
  name: "CardSiroRoela",
  props: {
      logo: String
  },
  watch: {

  },
  computed: {
    ...mapFieldsPago(["importe"]),
  },
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>
