<template>
  <div>
    <b-container class="mt-4">
      <b-row>
        <b-col coll="6" lg="6">
          <form-buscar-alumno />
        </b-col>
        <b-col coll="6" lg="6">
          <card-alumno v-if="this.alumno" :alumno="this.alumno">
          </card-alumno>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FormBuscarAlumno from "@/components/forms/FormBuscarAlumno.vue";
import CardAlumno from '@/components/cards/CardAlumno.vue';
import { createHelpers } from "vuex-map-fields";


const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});
export default {
  name: "BuscarAlumno",
  props: {
    titulo: String
  },
  watch: {
    alumno(newval, oldval) {
      console.log('alumno old', oldval);
      console.log('alumno new', newval);
    },
  },
  computed: {
    ...mapFieldsAlumno(["alumno","Dni", "mensajes"]),
    displayDebitoDirecto: function() {
      return this.alumno.DebitoDirecto
        ? this.mensajeDebito
        : this.mensajeNoDebito;
    },
    listacupones: function(){
      var lista = this.alumno ? this.alumno.Cupones.map(({Id, Estado, Importe}) => ({Id, Estado, Importe})) : [];
      return lista;
    },
    isValid: function() {
      if (this.alumno) {
        return true;
      } else {
        return false;
      }
    }
  },
  components: {
    FormBuscarAlumno,
    CardAlumno,
  },
  data() {
    return {
      mensajeDebito: "Adherido al débito automático",
      mensajeNoDebito: "No esta adherido al débito automático"
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>