<template>
  <div>
    <b-card header-tag="header" class="mt-4">
      <h4>
        <img :src="logo" /> Link Pagos
        <router-link to="/formapago" v-slot="{href, route, navigate}"  >
            <b-button :href="href" variant="light"  @click="navigate" class='mr-2'>
              Cambiar Forma de Pago
            </b-button>
        </router-link>
      </h4>
      <hr>
      <b-alert show variant="info">
          <b-badge variant="info">Aviso:</b-badge>
          Luego de realizar el pago a través de <u>LINK Pagos</u>, no es necesario informarlo. El mismo se verá reflejado en las próximas 72 horas
      </b-alert>
      <b-alert show variant="warning">
          <b-badge variant="warning">Aviso:</b-badge>
          El importe debe ser <b>${{ this.importe }}</b> para que el pago pueda ser validado
      </b-alert>
      <slot></slot>
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://www.youtube.com/embed/P5JIws42Rd4"
        allowfullscreen
      ></b-embed>
    </b-card>
  </div>
</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField"
});
export default {
  name: "CardLinkPagos",
  props: {
      logo: String
  },
  watch: {

  },
  computed: {
    ...mapFieldsPago(["importe"]),
  },
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>
