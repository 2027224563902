import axios from "axios";
import api from "@/api";
const path = "/NivelEnseñanza";

export default {
    getLista(obj) {
        return axios.get(`${api.alumnos + path}/GetLista`, { params: obj });
    },
    getEstablecimientos(unidadAdministrativa_Id) {
        return axios.get(`${api.alumnos + path}/GetLista`, { params: { limit: 500, unidadAdministrativa_Id: unidadAdministrativa_Id } });
    }
    // getEstablecimientos() {
    //     return axios.get(`${api.cdn}/establecimientos.json`);
    // }
};