<template>
  <div>
    <b-card bg-variant="light">
      <b-row>
        <b-col lg="12">
          <div id="mercadopagobtn">

          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import postscribe from 'postscribe'
import { createHelpers } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
// import moment from 'moment';
import swalerts from "@/helpers/swalerts";

const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField"
});
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField"
});
export default {
  name: "FormMercadoPago",
  mixins: [swalerts],
  components: {},
  computed: {
    ...mapGetters(["getSiteKey"]),
    ...mapGetters("AlumnoModule", ["alumnoIsValid"]),

    ...mapFieldsAlumno(["Dni", "mensajes"]),
    ...mapFieldsPago([
      "formapago",
      "importe",
      "cuponesseleccionados",
      "nivelenseñanza"
    ])
  },

  data() {
    return {
    };
  },

  methods: {
    ...mapActions("BancoModule", { getListaBanco: "getLista" }),
    ...mapActions("TransaccionBancariaModule", { crearTransaccionBancaria: 'crear'}),
    ...mapActions("CuentaBancariaModule", { crearCuentaBancaria: 'crear'}),
    ...mapActions("CuponModule", ["guardarTransferencia"]),
    //...mapActions(["loadSiteKey"]),
    onSelect(option) {
      console.log("onSelect", option);
    },
  },
  mounted() {
    //eslint-disable-next-line
    postscribe('#mercadopagobtn', `<script src="https://www.mercadopago.com.ar/integrations/v1/web-payment-checkout.js" data-preference-id="91370021-ac0b61f4-7d6c-43d3-8351-f374e503fbef"><\/script>`);
  },
  created() {}
};
</script>

<style scoped>
dt {
  float: left;
  clear: left;
  margin-right: 5px;
  font-weight: bold;
}

dd {
  margin-left: 0px;
}
</style>
