<template>
  <div id="perfil-view">
    <h1>Perfil</h1>
    {{displayUserInfo}}
    {{userInfo}}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed:{
    ...mapGetters('auth', ['userInfo']),
    displayUserInfo: function() {
      return 'hola';
    },
  },
  data() {
    return {
      perfil: null
    };
  },
  methods: {
    getAccountDetails() {
      this.$store.dispatch('auth/getAccountDetails', { })
        .then(r => {
            console.log(r);
            this.$router.push('/');
          }
        );
    },
  },
};
</script>

<style>
form input {
  display: block
}
</style>