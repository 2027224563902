<template>
  <div>
    <b-container class="mt-4" v-if="mostrarInformarProblema">
      <b-row>
        <b-col coll="8" lg="8">
          Informar un problema
          <form-informar-problema></form-informar-problema>
        </b-col>
        <b-col coll="4" lg="4">
          <card-alumno v-if="this.alumno" :alumno="this.alumno">
          </card-alumno>
          <card-detalle-pago></card-detalle-pago>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import CardAlumno from '@/components/cards/CardAlumno.vue';
import CardDetallePago from '@/components/cards/CardDetallePago.vue';
//import CardTransferencia from '@/components/cards/CardTransferencia.vue';
import FormInformarProblema from '@/components/forms/FormInformarProblema.vue'

const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});
export default {
  name: "InformarProblema",
  props: {
    titulo: String
  },
  watch: {
    alumno(newval, oldval) {
      console.log('alumno old', oldval);
      console.log('alumno new', newval);
    },
  },
  computed: {
    ...mapFieldsAlumno(["alumno","Dni", "mensajes"]),

  },
  components: {
    //CardTransferencia,
    FormInformarProblema,
    CardAlumno,
    CardDetallePago
  },
  data() {
    return {
      mostrarInformarProblema: !!(process.env.VUE_APP_APIKEY_OSTICKET)
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>