<template>
  <div>
    <b-card header="Detalle del pago" header-tag="header" class="mt-4 text-left">
      <b-card-body class="text-right">
        <b-card-text >
          <h2>Total: {{ (this.importe ? this.importe : 0 ) | numeral('$0,0.00')   }}</h2>
          <h6 v-if="this.formapago !== -1 && this.$route.path !== '/formapago'">Forma de pago: {{ displayFormaPago }}</h6>
        </b-card-text>
        <b-button v-if="true" v-b-modal.modal-detallepago variant="primary">
          Ver Cupones seleccionados  <b-badge variant="light"> {{ this.cuponesseleccionados.length }}</b-badge>
        </b-button>
      </b-card-body>
      <slot></slot>
    </b-card>
    <b-modal size="lg" id="modal-detallepago" title="Detalle del pago"  hide-footer>
      <div class="d-block text-center">
        <table-cupones :columnas="['ConceptoString','Id','Estado','UltimoVencimiento','ImporteVencimiento']"  :selectable="false" selectmode="single" :listacupones="this.cuponesseleccionados">
        </table-cupones>
        <b-button @click="$bvModal.hide('modal-detallepago')">Cerrar </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { createHelpers } from "vuex-map-fields";
import TableCupones from '@/components/TableCupones.vue';
const { mapFields } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});
export default {
  name: "CardDetallePago",
  props: {
  },
  watch: {
    cuponesseleccionados(newval,oldval){
      console.log('cupones old', oldval);
      console.log('cupones new', newval);
      this.importe = this.cuponesseleccionados.map(c => c.ImporteVencimiento).reduce((a, b) => a + b, 0);
    }
  },
  computed: {
      ...mapFields(['importe','formapago','cuponesseleccionados','estadotransaccion']),
      displayFormaPago(){
        if(this.formapago === 0){
          return 'Caja Municipal';
        }
        if(this.formapago === 1){
          return 'Transferencia';
        }
        if(this.formapago === 2){
          return 'Depósito';
        }
        if(this.formapago === 4){
          return 'Debito Directo';
        }
        if(this.formapago === 5){
          return 'Link Pagos';
        }
        return this.formapago;
      }
  },
  components: {
    TableCupones,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>