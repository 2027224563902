<template>
  <div>
    <b-container class="mt-4">
      <b-row>
        <b-col coll="8" lg="8">
          <card-forma-pago></card-forma-pago>
        </b-col>
        <b-col coll="4" lg="4">
          <card-alumno v-if="this.alumno" :alumno="this.alumno">
          </card-alumno>
          <card-detalle-pago></card-detalle-pago>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { createHelpers } from "vuex-map-fields";
import CardAlumno from '@/components/cards/CardAlumno.vue';
import CardDetallePago from '@/components/cards/CardDetallePago.vue';
import CardFormaPago from '@/components/cards/CardFormaPago.vue';

const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});
export default {
  name: "BuscarAlumno",
  props: {
    titulo: String
  },
  watch: {
    alumno(newval, oldval) {
      console.log('alumno old', oldval);
      console.log('alumno new', newval);
    },
  },
  computed: {
    ...mapFieldsAlumno(["alumno","Dni", "mensajes"]),

  },
  components: {
    CardAlumno,
    CardDetallePago,
    CardFormaPago
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>