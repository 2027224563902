var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{attrs:{"to":"/vercupones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","href":href,"variant":"info"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Adeudados" : route.name)+" ")])]}}])}),_c('router-link',{attrs:{"to":"/vercuponespagados"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","href":href,"variant":"success"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Pagados" : route.name)+" ")])]}}])}),_c('router-link',{attrs:{"to":"/vercuponespendientes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","href":href,"variant":"warning"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Pendientes" : route.name)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }