<template>
  <div>
    <b-card header-tag="header" class="mt-4">
      <h4>
        <img src="img/tr38.png" />
        <router-link to="/formapago" v-slot="{href, route, navigate}"  >
            <b-button :href="href" variant="light"  @click="navigate" class='mr-2'>
              Cambiar Forma de Pago
            </b-button>
        </router-link>
      </h4>
      <hr>
      <slot></slot>
      <p>En esta forma de pago se debe registrar el siguiente formulario para poder validar el pago dentro de 72 hs.</p>
      <form-transferencia></form-transferencia> 
    </b-card>
  </div>
</template>
<script>
import FormTransferencia from '@/components/forms/FormTransferencia.vue';
import swalerts from '@/helpers/swalerts';

export default {
  name: "CardTransferencia",
  mixins: [swalerts],
  props: {
  },
  watch: {

  },
  computed: {
  },
  components: {
    FormTransferencia
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>
