<template>
  <div>
    <b-card bg-variant="light">
      <b-row>
        <b-col lg="12">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider name="fechaPago" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group id="inputFechaPago-group" label="Fecha de pago" label-for="inputFechaPago">
                  <b-form-datepicker
                    id="inputFechaPago"
                    v-model="inputFechaPago.value"
                    placeholder="Fecha de pago"
                    class="mb-2"
                    :min="inputFechaPago.min"
                    :max="inputFechaPago.max"
                    :state="checkForm(validationContext)"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback id="inputFechaPago-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  <dl>
                    <dt><h4>{{labelImporte}}:</h4></dt>
                    <dd><h4><b>{{ (importe ? importe : 0) | numeral("$0,0.00") }}</b></h4></dd>
                  </dl>
                </b-form-group>
              </validation-provider>
              <label class="typo__label"><strong>2. Diríjase a la sucursal del banco y realice el depósito utilizando estos datos: </strong></label>
              <card-transferencia-cuenta></card-transferencia-cuenta>
              <label class="typo__label">2.1 Si el importe del depósito no es <b>{{ (importe ? importe : 0) | numeral("$0,0.00") }}</b> el pago no podrá ser validado.</label>
              <label class="typo__label"><strong>3. Una vez realizado el depósito complete los siguientes datos:</strong></label>
                        
              <validation-provider name="nroComprobante" :rules="{ required: true, integer: true }" v-slot="validationContext">
                <b-form-group id="inputNroComprobante-group" label="N° de Comprobante" label-for="inputNroComprobante">
                  <b-form-input
                    id="inputNroComprobante"
                    v-model="inputNroComprobante"
                    placeholder="N° de Comprobante"
                    :state="checkForm(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback id="nroComprobante-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider name="comprobante" :rules="{ required: false, ext: ['jpg', 'png', 'pdf'], size: 3072 }" v-slot="validationContext">
                <b-form-group id="inputComprobante-group" label="Comprobante (opcional)" label-for="inputComprobante">
                  <!-- <div class="mt-3">Archivo seleccionado: {{ inputComprobante ? inputComprobante.name : '' }}</div> -->
                  <b-form-file
                    class="mb-2"
                    browse-text="Adjuntar 📎"
                    v-model="inputComprobante"
                    placeholder="Archivo jpg, png, pdf"
                    drop-placeholder="Suelte el archivo aquí..."
                    :state="checkForm(validationContext)"
                  ></b-form-file>
                  <!-- <p v-if="inputComprobante">{{inputComprobante.name}}</p> -->
                  <b-button v-if="inputComprobante" @click="inputComprobante = null">Remover archivo </b-button>
                  <b-form-invalid-feedback id="inputComprobante-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-button class="mt-2" type="submit" variant="success">Enviar</b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import swalerts from "@/helpers/swalerts";
import errorhandler from "@/helpers/errorhandler";
import moment from 'moment';
import { createHelpers } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import CardTransferenciaCuenta from '@/components/cards/CardTransferenciaCuenta.vue';
// import moment from 'moment';

const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});

export default {
  name: "FormDeposito",
    mixins: [errorhandler, swalerts],
  components: {
    CardTransferenciaCuenta
  },
  watch: {
    inputFechaPagoValue: function (newVal, oldVal) {
      console.log('inputFechaPago', newVal, oldVal);
      console.log(this.cuponesseleccionados);
      if(this.cuponesseleccionados.length == 1){
        let fechaVencimiento = moment(moment(this.cuponesseleccionados[0].FechaVencimiento).format("YYYY-MM-DD"));
        let fechaPago = moment(moment(newVal, 'YYYY-MM-DD').format("YYYY-MM-DD"));
        let today = moment(moment().format("YYYY-MM-DD"));
        console.log(today);
        if(fechaPago.isSame(today)){
          console.log('fechaPago isSame today', fechaPago, today);
          this.labelImporte = 'Importe a pagar';
          this.isToday = true;
        }else{
          this.labelImporte = 'Importe pagado';
          this.isToday = false;
        }
        if(fechaPago.isSameOrBefore(fechaVencimiento)){
          console.log('fechaPago isBefore FechaVencimiento', fechaPago, fechaVencimiento);
          this.importe = this.cuponesseleccionados[0].Importe;
        }else{
          console.log('fechaPago isAfter FechaVencimiento', fechaPago, fechaVencimiento);
          this.importe = this.cuponesseleccionados[0].ImporteVencimiento;
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getSiteKey"]),
    ...mapGetters("AlumnoModule", ["alumnoIsValid"]),
    ...mapFieldsAlumno(["Dni", "mensajes", "alumno"]),
    ...mapFieldsPago(['formapago','importe', "cuponesseleccionados","nivelenseñanza"]),
    inputFechaPagoValue() {
      return this.inputFechaPago.value;
    }
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 6);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    return {
      labelImporte:'Importe a pagar',
      inputNroComprobante: null,
      inputFechaPago:{
        value: null,
        min: minDate,
        max: maxDate
      },
      inputComprobante: null,
    }
  },

  methods: {
    ...mapActions("TransaccionBancariaModule", { crearTransaccionBancaria: 'crear'}),
    ...mapActions("CuponModule", ["guardarTransferencia"]),
    ...mapActions("BancoModule", { getListaBanco: "getLista" }),
    //...mapActions(["loadSiteKey"]),
    onSelect (option) {
      console.log('onSelect',option);
    },
    enviarDeposito(){
      console.log('enviarDeposito');
      //this.checkForm();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      //alert("Form submitted!");
      var querytransaccion = {};
      //var querycuenta = {};
      var querydeposito = {};
      // queryparams.cupones = this.cuponesseleccionados;
      //querycuenta.entidadbancaria_id = this.selectBancoOrigen.selected.Id;
      //querycuenta.cuil = this.inputCuentaOrigenCuil;
      //querycuenta.cuit = this.inputCuentaOrigenCuil;
      //querycuenta.nombre = this.inputCuentaOrigenNombre;
      //querycuenta.apellido = this.inputCuentaOrigenApellido;
      // queryparams.fechapago = this.inputFechaPago.value;
      //querycuenta.cbu = this.inputCuentaOrigen;
      //querycuenta.tipotitularcuentabancaria = this.optionsTipo.selected;

      console.log(this.nivelenseñanza);
      querytransaccion.destino_id = 39;
      querytransaccion.fecha = this.inputFechaPago.value;
      querytransaccion.nrocomprobante = this.inputNroComprobante;
      querytransaccion.importetotal = this.importe;
      querytransaccion.tipotransaccionbancaria = 1; //1- Deposito
      querytransaccion.nivelenseñanza_id = this.nivelenseñanza.Id;
      querytransaccion.unidadeducativa_id = this.nivelenseñanza.UnidadEducativa_Id;
      querytransaccion.unidadadministrativa_id = this.nivelenseñanza.UnidadAdministrativa_Id;
      console.log('querytransaccion', querytransaccion);
      console.log('inputComprobante', this.inputComprobante);

      this.showConfirmarInfo(
        `Enviar los siguientes datos del depósito <br />nro de comprobante: ${querytransaccion.nrocomprobante}<br />fecha: ${this.inputFechaPago.value}  <br />importe: ${this.importe}`,
        "Enviar"
      ).then(result => {
        console.log(result);
        if (result.value) {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer
            //canCancel: true,
            //onCancel: this.onCancel,
          });
          var cuponselected = "";
          this.cuponesseleccionados.forEach( function(valor, indice) {
              console.log("En el índice " + indice + " hay este valor: " + valor);
              cuponselected = cuponselected + `&cuponselected=${valor.Id}`;
          });
          let formData = new FormData();
          formData.append('file', this.inputComprobante);
          formData.append('serverpath', 'depositos');
          let filenameComprobante = this.inputComprobante ? this.inputComprobante.name : "";
          let comprobantename =`${moment().format('YYYYMMDDHHmmss')}_${filenameComprobante}`;
          formData.append('filename', comprobantename);

          this.getCaptcha().then(cpha => {
            querytransaccion.origen_id = null;
            querytransaccion.grecaptcha = cpha;
            querytransaccion.appVersion = process.env.VUE_APP_VERSION;
            this.formapago = -1;
            this.crearTransaccionBancaria(querytransaccion).then(rcta => {
              console.log('rcta', rcta);
              this.getCaptcha().then(cphb => {
                querydeposito.transaccionbancaria_id = rcta.objeto.Nuevo.Id;
                querydeposito.uploadfile = this.inputComprobante ? true : false;
                querydeposito.formdata = formData;
                querydeposito.idNivel = this.nivelenseñanza.Id;
                querydeposito.cuponselected = cuponselected;
                querydeposito.grecaptcha = cphb;
                querydeposito.appVersion = process.env.VUE_APP_VERSION;

                this.guardarTransferencia(querydeposito)
                  .then(rtran => {
                    loader.hide();
                    console.log(rtran);
                    this.formapago = -1;
                    this.showSuccess("Depósito Guardado. Pendiente de aprobación.");
                    this.alumno = null;
                    this.cuponesseleccionados = [];
                    this.importe = 0;
                    this.$router.push('buscaralumno');
                  })
                  .catch(etran => {
                    console.log(etran);
                    if(etran.response){
                      this.showError(etran.response.data.status.mensaje);
                    }else{
                      this.showError(etran);
                    }
                    loader.hide();
                  });
              });
            })
            .catch(ecta => {
              console.log(ecta);
              if(ecta.response){
                this.showError(ecta.response.data.status.mensaje);
              }else{
                this.showError(ecta);
              }
              loader.hide();
            });
          });

        }
      });
    },
    async getCaptcha(){
      var enableRecaptcha = (process.env.VUE_APP_ENABLE_RECAPTCHA === 'true');
      console.log('enableRecaptcha', enableRecaptcha);
      var token = '';
      if(enableRecaptcha){
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded();
  
        // Execute reCAPTCHA with action "deposito".
        token = await this.$recaptcha('deposito');
        return token;
      }
      return '';
    },
    checkForm: function({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }
  },
  created() {
  },
};
</script>

<style  scoped>
dt {
  float: left;
  clear: left;
  margin-right: 5px;
  font-weight: bold;
}

dd {
  margin-left: 0px;
}
</style>