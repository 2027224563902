import AlumnoSvc from "../services/alumno";

import { createHelpers } from "vuex-map-fields";

const { getAlumnoField, updateAlumnoField } = createHelpers({
    getterType: "getAlumnoField",
    mutationType: "updateAlumnoField"
});

export default {
    namespaced: true,
    state: {
        Dni: "",
        alumno: null,
        idNivel:0,
        mensajes: {
            error: "Alumno no encontrado"
        }
    },
    mutations: {
        updateAlumnoField,
        setAlumno(state, data) {
            console.log('setAlumno',data);
            console.log('setAlumno',state);
            state.alumno = data;
        },
        setIdNivel(state, data) {
            state.idNivel = data;
        },
        setDni(state, data) {
            state.Dni = data;
        }
    },
    actions: {
        findAlumno(ctx, data) {
            console.log("alumnoModule.action.findAlumno", data);
            ctx.commit("setIdNivel", data.nivelenseñanza_id);
            return AlumnoSvc.find(data).then(r => {
                ctx.commit("setAlumno", r.data.objeto);
                return r.data.objeto;
            });
        },
        cleanAlumno(ctx) {
            console.log("alumnomodule.action.clean", ctx);
            ctx.commit("setDni", "");
            ctx.commit("setAlumno", null);
        }
    },
    getters: {
        getAlumnoField,
        alumnoIsValid(state) {
            return (
                state.alumno !== null &&
                typeof state.alumno !== "undefined" &&
                state.alumno !== false
            );
        }
    }
};