import Vue from "vue";
import Vuex from "vuex";
import { getField, updateField } from "vuex-map-fields";
import AlumnoModule from "./alumnomodule.js";
import NivelModule from "./nivelmodule.js";
import BancoModule from "./bancomodule.js";
import PagoModule from "./pagomodule.js";
import TransaccionBancariaModule from "./transaccionbancariamodule.js";
import CuentaBancariaModule from "./cuentabancariamodule.js";
import CuponModule from "./cuponmodule.js";
import auth from './auth/auth';
import signup from './auth/signup';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth,
        signup,
        AlumnoModule: AlumnoModule,
        NivelModule: NivelModule,
        BancoModule: BancoModule,
        PagoModule: PagoModule,
        TransaccionBancariaModule: TransaccionBancariaModule,
        CuentaBancariaModule: CuentaBancariaModule,
        CuponModule: CuponModule
    },
    state: {
      siteKey: "",
      isLoading: false,
      isMobile: false
    },
    getters: {
        getField,
        getIsLoading(state) {
            return state.isLoading;
        },
        getSiteKey(state) {
          return state.siteKey;
        },
        getIsMobile(state) {
          return state.isMobile;
        },
    },
    actions: {

    },
    mutations: {
      updateField,
      setIsLoading(state, value) {
        state.isLoading = value;
      },
      setSiteKey(state, value) {
        state.siteKey = value;
      },
      setIsMobile(state, value) {
        state.isMobile = value;
      },
    }
});

export default store;