export default {
  methods: {
    showSuccess(mensaje, title = "¡Buen trabajo!"){
      this.$swal({
        title: title,
        text: mensaje, 
        icon: "success",
        buttons: false,
        //timer: 15000
      });
    },
    showError(mensaje){
      this.$swal({
        title: "¡Ocurrió un error!", 
        text: mensaje, 
        icon: "error",
        buttons: false,
        //timer: 15000
      });
    },
    showConfirmarDanger(mensaje, btnText){
      return this.$swal({
        reverseButtons: true,
        title: "Estas seguro?",
        text: mensaje,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonColor: '#5a6268',
        confirmButtonText: btnText,
        cancelButtonText: 'Cancelar'
      });
    },
    showConfirmarInfo(mensaje, btnText){
      return this.$swal({
        reverseButtons: true,
        title: "Confirmar acción.",
        html: mensaje,
        //text: 'text' + mensaje,
        icon: 'info',
        showCancelButton: true,
        //confirmButtonColor: '#28a745',
        cancelButtonColor: '#5a6268',
        confirmButtonText: btnText,
        cancelButtonText: 'Cancelar'
      });
    },
    showConfirmarOnly(titulo, mensaje, btnText){
      return this.$swal({
        title: titulo,
        html: mensaje,
        icon: 'info',
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: btnText,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
    showErrorOnly(mensaje){
      this.$swal({
        title: "¡Ocurrió un error!", 
        text: mensaje, 
        icon: "error",
        buttons: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });
    },
  }
}
