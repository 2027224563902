<template>
  <div>
    <b-card bg-variant="light">
      <b-row>
        <b-col lg="12">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <validation-provider
              name="bancoOrigen"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group id="selectBancoOrigen-group" label-for="selectBancoOrigen">
                <label class="typo__label">
                  <strong>1. Elegir banco o entidad de Origen</strong>
                </label>
                <multiselect
                  v-model="selectBancoOrigen.selected"
                  id="selectBancoOrigen"
                  label="Nombre"
                  track-by="Id"
                  placeholder="Escriba el nombre del banco"
                  :options="selectBancoOrigen.options"
                  :searchable="true"
                  :clear-on-select="false"
                  @select="onSelect"
                  :close-on-select="true"
                  :show-no-results="true"
                  selectLabel="Enter para seleccionar"
                  selectedLabel="Seleccionado"
                  deselectLabel="Enter para remover"
                  noOptions="Sin resultados"
                  :allow-empty="true"
                >
                  <span slot="noResult">Su búsqueda no arrojó ningún resultado. Si no encuentra su entidad de origen en el listado debe informarlo para darla de alta.</span>
                </multiselect>
                <b-form-invalid-feedback id="selectBancoOrigen-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form v-if="selectBancoOrigen.selected" enctype="multipart/form-data" @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider name="fechaPago" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group id="inputFechaPago-group" label="Fecha de pago" label-for="inputFechaPago">
                  <b-form-datepicker
                    id="inputFechaPago"
                    v-model="inputFechaPago.value"
                    placeholder="Fecha de pago"
                    class="mb-2"
                    :min="inputFechaPago.min"
                    :max="inputFechaPago.max"
                    :state="checkForm(validationContext)"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback id="inputFechaPago-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  <dl>
                    <dt v-if="!getIsMobile"><h4>{{labelImporte}}:</h4></dt>
                    <dt v-if="getIsMobile"><b>{{labelImporte}}:</b></dt>
                    <dd v-if="!getIsMobile"><h4><b>{{ (importe ? importe : 0) | numeral("$0,0.00") }}</b></h4></dd>
                    <dd v-if="getIsMobile"><b>{{ (importe ? importe : 0) | numeral("$0,0.00") }}</b></dd>
                  </dl>
                </b-form-group>
              </validation-provider>
              <label class="typo__label"><strong>2. Ingrese en su página de Home Banking ({{ selectBancoOrigen.selected ? selectBancoOrigen.selected.Nombre : "" }}) los siguientes datos y
                realice la transferencia:</strong>
              </label>
              <card-transferencia-cuenta></card-transferencia-cuenta>

              <label class="typo__label">2.1 Si el importe de la transferencia no es <b>{{ (importe ? importe : 0) | numeral("$0,0.00") }}</b> el pago no podrá ser validado.</label>
              <label class="typo__label"><strong>3. Una vez finalizada la transferencia complete los siguientes datos:</strong></label>

              <validation-provider name="nroComprobante" :rules="{ required: true, integer: true }" v-slot="validationContext">
                <b-form-group id="inputNroComprobante-group" label="N° de Comprobante" label-for="inputNroComprobante">
                  <b-form-input
                    id="inputNroComprobante"
                    v-model="inputNroComprobante"
                    placeholder="N° de Comprobante"
                    :state="checkForm(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback id="nroComprobante-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>


              <validation-provider name="comprobante" :rules="{ required: false, ext: ['jpg', 'png', 'pdf'], size: 3072 }" v-slot="validationContext">
                <b-form-group id="inputComprobante-group" label="Comprobante (opcional)" label-for="inputComprobante">
                  <!-- <div class="mt-3">Archivo seleccionado: {{ inputComprobante ? inputComprobante.name : '' }}</div> -->
                  <b-form-file
                    class="mb-2"
                    browse-text="Adjuntar 📎"
                    v-model="inputComprobante"
                    placeholder="Archivo jpg, png, pdf"
                    drop-placeholder="Suelte el archivo aquí..."
                    :state="checkForm(validationContext)"
                  ></b-form-file>
                  <!-- <p v-if="inputComprobante">{{inputComprobante.name}}</p> -->
                  <b-button v-if="inputComprobante" @click="inputComprobante = null">Remover archivo </b-button>
                  <b-form-invalid-feedback id="inputComprobante-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <b-form-group label="Marque según corresponda a la titularidad de la cuenta de origen.">
                <b-form-radio-group id="radio-group-1" v-model="optionsTipo.selected" :options="optionsTipo.options" name="radio-options"></b-form-radio-group>
              </b-form-group>
              <validation-provider name="cuentaOrigen" :rules="{ required: true, digits: 22 }" v-slot="validationContext">
                <b-form-group id="inputCuentaOrigen-group" label="CBU o CVU de la cuenta de origen" label-for="inputCuentaOrigen">
                  <b-form-input
                    v-model="inputCuentaOrigen"
                    placeholder="Número de CBU o CVU de la cuenta de origen"
                    :state="checkForm(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputCuentaOrigen-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider v-if="optionsTipo.selected === 1" name="cuentaOrigenCuil" :rules="{ required: true, digits: 11 }" v-slot="validationContext">
                <b-form-group id="inputCuentaOrigenCuil-group" label="Titular de la cuenta (Nº de CUIL)." label-for="inputCuentaOrigenCuil">
                  <b-form-input
                    id="inputCuentaOrigenCuil"
                    v-model="inputCuentaOrigenCuil"
                    placeholder="Nº de CUIL sin guiones"
                    :state="checkForm(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputCuentaOrigenCuil-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider v-if="optionsTipo.selected === 2" name="cuentaOrigenCuit" :rules="{ required: true, digits: 11 }" v-slot="validationContext">
                <b-form-group id="inputCuentaOrigenCuit-group" label="Titular de la cuenta (Nº de CUIT)." label-for="inputCuentaOrigenCuit">
                  <b-form-input
                    id="inputCuentaOrigenCuit"
                    v-model="inputCuentaOrigenCuit"
                    placeholder="Nº de CUIT sin guiones"
                    :state="checkForm(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputCuentaOrigenCuit-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider name="cuentaOrigenNombre" :rules="{ required: true, alpha_spaces: true }" v-slot="validationContext">
                <b-form-group id="inputCuentaOrigenNombre-group" label="Titular de la cuenta bancaria de origen (Nombre)" label-for="inputCuentaOrigenNombre">
                  <b-form-input
                    id="inputCuentaOrigenNombre"
                    v-model="inputCuentaOrigenNombre"
                    placeholder="Nombre"
                    :state="checkForm(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputCuentaOrigenNombre-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                v-if="optionsTipo.selected === 1"
                name="cuentaOrigenApellido"
                :rules="{ required: true, alpha_spaces: true }"
                v-slot="validationContext"
              >
                <b-form-group
                  id="inputCuentaOrigenApellido-group"
                  label="Titular de la cuenta bancaria de origen (Apellido)"
                  label-for="inputCuentaOrigenNombre"
                >
                  <b-form-input
                    id="inputCuentaOrigenApellido"
                    v-model="inputCuentaOrigenApellido"
                    placeholder="Apellido"
                    :state="checkForm(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback id="inputCuentaOrigenApellido-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-button :disabled="!selectBancoOrigen.selected" class="mt-2" type="submit" variant="success">Enviar</b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import { createHelpers } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
// import moment from 'moment';
import swalerts from "@/helpers/swalerts";
import errorhandler from "@/helpers/errorhandler";
import CardTransferenciaCuenta from '@/components/cards/CardTransferenciaCuenta.vue';
//import { extend } from "vee-validate"; //import extend function from vee-validate
// extend("size", {
//   message:
//     "{_field_} Test",
//   validate: value => {
//     console.log('validate size', value);

//     return false; //Test the regex. Test function returns a true or false value.
//   }
// });
const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField"
});
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField"
});
export default {
  name: "FormTransferencia",
  mixins: [errorhandler, swalerts],
  components: { CardTransferenciaCuenta },
  watch: {
    inputFechaPagoValue: function (newVal, oldVal) {
      console.log('inputFechaPago', newVal, oldVal);
      console.log(this.cuponesseleccionados);
      if(this.cuponesseleccionados.length == 1){
        let fechaVencimiento = moment(moment(this.cuponesseleccionados[0].FechaVencimiento).format("YYYY-MM-DD"));
        let fechaPago = moment(moment(newVal, 'YYYY-MM-DD').format("YYYY-MM-DD"));
        let today = moment(moment().format("YYYY-MM-DD"));
        console.log(today);
        if(fechaPago.isSame(today)){
          console.log('fechaPago isSame today', fechaPago, today);
          this.labelImporte = 'Importe a pagar';
          this.isToday = true;
        }else{
          this.labelImporte = 'Importe pagado';
          this.isToday = false;
        }
        if(fechaPago.isSameOrBefore(fechaVencimiento)){
          console.log('fechaPago isBefore FechaVencimiento', fechaPago, fechaVencimiento);
          this.importe = this.cuponesseleccionados[0].Importe;
        }else{
          console.log('fechaPago isAfter FechaVencimiento', fechaPago, fechaVencimiento);
          this.importe = this.cuponesseleccionados[0].ImporteVencimiento;
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getSiteKey", "getIsMobile"]),
    ...mapGetters("AlumnoModule", ["alumnoIsValid"]),

    ...mapFieldsAlumno(["Dni", "mensajes", "alumno"]),
    ...mapFieldsPago(["formapago", "importe", "cuponesseleccionados", "nivelenseñanza"]),
    inputFechaPagoValue() {
      return this.inputFechaPago.value;
    },
    importeFormateado() {
      var importeFormateado = new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(this.importe);
      console.log(importeFormateado);
      return `${importeFormateado}`;
    }
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 6);
    minDate.setDate(15);
    // 15th in two months
    const maxDate = new Date(today);
    return {
      labelImporte:'Importe a pagar',
      isToday: false,
      nroComprobante: "",
      selectBancoOrigen: {
        selected: null,
        options: []
      },
      inputFechaPago: {
        value: new Date(),
        min: minDate,
        max: maxDate
      },
      inputNroComprobante: null,
      inputComprobante: null,
      inputCuentaOrigen: null,
      inputCuentaOrigenNombre: null,
      inputCuentaOrigenApellido: null,
      inputCuentaOrigenCuil: null,
      inputCuentaOrigenCuit: null,
      optionsTipo: {
        selected: 1,
        options: [
          { text: "Persona", value: 1 },
          { text: "Organizacion", value: 2 }
        ]
      }
    };
  },

  methods: {
    ...mapActions("BancoModule", { getListaBanco: "getLista" }),
    ...mapActions("TransaccionBancariaModule", { crearTransaccionBancaria: "crear" }),
    ...mapActions("CuentaBancariaModule", { crearCuentaBancaria: "crear" }),
    ...mapActions("CuponModule", ["guardarTransferencia"]),
    //...mapActions(["loadSiteKey"]),
    onSelect(option) {
      console.log("onSelect", option);
    },
    getSelectBancos() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer
        //canCancel: true,
        //onCancel: this.onCancel,
      });
      this.getListaBanco({ limit: 500 })
        .then(r => {
          loader.hide();
          r.data.rows.forEach(element => {
            //console.log(element);
            this.selectBancoOrigen.options.push(element);
          });
        })
        .catch(e => {
          this.errorHandler(e, loader);
          console.log(e);
        });
    },
    async getCaptcha() {
      var enableRecaptcha = process.env.VUE_APP_ENABLE_RECAPTCHA === "true";
      console.log("enableRecaptcha", enableRecaptcha);
      var token = "";
      if (enableRecaptcha) {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded();

        // Execute reCAPTCHA with action "transferencia".
        token = await this.$recaptcha("transferencia");
        return token;
      }
      return "";
    },
    enviarTransferencia() {
      console.log("envairTrasnferencia");
      //this.checkForm();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      //alert("Form submitted!");
      var querytransaccion = {};
      var querycuenta = {};
      var querytransferencia = {};
      // queryparams.cupones = this.cuponesseleccionados;
      querycuenta.entidadbancaria_id = this.selectBancoOrigen.selected.Id;
      querycuenta.cuil = this.inputCuentaOrigenCuil;
      querycuenta.cuit = this.inputCuentaOrigenCuil;
      querycuenta.nombre = this.inputCuentaOrigenNombre;
      querycuenta.apellido = this.inputCuentaOrigenApellido;
      // queryparams.fechapago = this.inputFechaPago.value;
      querycuenta.cbu = this.inputCuentaOrigen;
      querycuenta.tipotitularcuentabancaria = this.optionsTipo.selected;

      console.log(this.nivelenseñanza);
      querytransaccion.destino_id = 39;
      querytransaccion.fecha = moment(this.inputFechaPago.value).format("YYYY-MM-DD");
      querytransaccion.nrocomprobante = this.inputNroComprobante;
      querytransaccion.importetotal = this.importe;
      querytransaccion.tipotransaccionbancaria = 2;
      querytransaccion.nivelenseñanza_id = this.nivelenseñanza.Id;
      querytransaccion.unidadeducativa_id = this.nivelenseñanza.UnidadEducativa_Id;
      querytransaccion.unidadadministrativa_id = this.nivelenseñanza.UnidadAdministrativa_Id;
      console.log("querytransaccion", querytransaccion);
      console.log("inputComprobante", this.inputComprobante);

      this.showConfirmarInfo(
        `<b>Enviar los siguientes datos de la transferencia</b></b> <br />
        Nro de comprobante: ${querytransaccion.nrocomprobante}<br />
        Banco: ${this.selectBancoOrigen.selected.Nombre}<br />
        Nombre: ${this.inputCuentaOrigenNombre} ${this.inputCuentaOrigenApellido}<br />
        CUIL/CUIT: ${this.inputCuentaOrigenCuil}<br />
        CBU/CVU: ${this.inputCuentaOrigen}<br />
        Fecha: ${moment(this.inputFechaPago.value).format("DD/MM/YYYY")}<br />
        Importe:  ${this.importeFormateado}<br>
        <b>Si el importe de la transferencia no es ${this.importeFormateado} el pago no podrá ser validado.</b>
        `,
        "Enviar"
      ).then(result => {
        console.log(result);
        if (result.value) {
          let loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer
            //canCancel: true,
            //onCancel: this.onCancel,
          });
          var cuponselected = "";
          this.cuponesseleccionados.forEach(function(valor, indice) {
            console.log("En el índice " + indice + " hay este valor: " + valor);
            cuponselected = cuponselected + `&cuponselected=${valor.Id}`;
          });
          let formData = new FormData();
          formData.append("file", this.inputComprobante);
          formData.append("serverpath", "transferencias");
          let filenameComprobante = this.inputComprobante ? this.inputComprobante.name : "";
          let comprobantename = `${moment().format("YYYYMMDDHHmmss")}_${filenameComprobante}`;
          formData.append("filename", comprobantename);
          this.getCaptcha().then(cph => {
            querycuenta.grecaptcha = cph;
            querycuenta.appVersion = process.env.VUE_APP_VERSION;
            this.crearCuentaBancaria(querycuenta)
              .then(res => {
                console.log(res);

                this.getCaptcha().then(cpha => {
                  querytransaccion.origen_id = res.objeto.Nuevo.Id;
                  querytransaccion.grecaptcha = cpha;
                  querytransaccion.appVersion = process.env.VUE_APP_VERSION;
                  this.formapago = -1;
                  this.crearTransaccionBancaria(querytransaccion)
                    .then(rcta => {
                      console.log("rcta", rcta);
                      this.getCaptcha().then(cphb => {
                        querytransferencia.transaccionbancaria_id = rcta.objeto.Nuevo.Id;
                        querytransferencia.uploadfile = this.inputComprobante ? true : false;
                        querytransferencia.formdata = formData;
                        querytransferencia.idNivel = this.nivelenseñanza.Id;
                        querytransferencia.cuponselected = cuponselected;
                        querytransferencia.grecaptcha = cphb;
                        querytransferencia.appVersion = process.env.VUE_APP_VERSION;

                        this.guardarTransferencia(querytransferencia)
                          .then(rtran => {
                            loader.hide();
                            console.log(rtran);
                            this.formapago = -1;
                            this.showSuccess("Transferencia Guardada. Pendiente de aprobación.");
                            this.alumno = null;
                            this.cuponesseleccionados = [];
                            this.importe = 0;
                            this.$router.push("buscaralumno");
                          })
                          .catch(etran => {
                            console.log(etran);
                            if (etran.response) {
                              this.showError(etran.response.data.status.mensaje);
                            } else {
                              this.showError(etran);
                            }
                            loader.hide();
                          });
                      });
                    })
                    .catch(ecta => {
                      console.log(ecta);
                      if (ecta.response) {
                        this.showError(ecta.response.data.status.mensaje);
                      } else {
                        this.showError(ecta);
                      }
                      loader.hide();
                    });
                });
              })
              .catch(err => {
                console.log(err);
                if (err.response) {
                  this.showError(err.response.data.status.mensaje);
                } else {
                  this.showError(err);
                }
                loader.hide();
              });
          });
        }
      });
    },
    checkForm: function({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    }
  },
  created() {
    this.getSelectBancos();
  }
};
</script>

<style scoped>
dt {
  float: left;
  clear: left;
  margin-right: 5px;
  font-weight: bold;
}

dd {
  margin-left: 0px;
}
</style>
