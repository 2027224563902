import BancoSvc from "../services/banco";

import { createHelpers } from "vuex-map-fields";

const { getBancoField, updateBancoField } = createHelpers({
    getterType: "getBancoField",
    mutationType: "updateBancoField"
});
export default {
    namespaced: true,
    state: {
        banco:null,
        tabla: {
            items: [],
            fields: [{ key: "id" }],
            rows: [],
            isBusy: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50]
        }
    },
    mutations: {
        updateBancoField,

        setTablaRows(state, data) {
            console.log("mutation.setTabla", data);
            state.tabla.rows = data.rows;
            state.tabla.totalRows = data.total;
        }
    },
    actions: {
        getLista(ctx, obj) {
            return BancoSvc.getLista(obj);
        }
    },
    getters: {
        getBancoField
    }
};