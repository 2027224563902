import { createHelpers } from "vuex-map-fields";

const { getPagoField, updatePagoField } = createHelpers({
    getterType: "getPagoField",
    mutationType: "updatePagoField"
});
export default {
    namespaced: true,
    state: {
        pago: null,
        estadotransaccion: 'sinestado',//0-sinestado, 1-buscar, 2-formapago, 3-enviar formulario
        nivelenseñanza: 0,
        importe: 0.0,
        formapago: -1,
        ciclolectivo: new Date().getFullYear(),
        tienematricula: true,
        cuponesseleccionados: [],
        tabla: {
            items: [],
            fields: [{ key: "id" }],
            rows: [],
            isBusy: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50]
        }
    },
    mutations: {
        updatePagoField,
    },
    actions: {

    },
    getters: {
        getPagoField
    }
};