import { createHelpers } from "vuex-map-fields";
import CuentaBancariaSvc from "../services/cuentabancaria";

const { getCuentaBancariaField, updateCuentaBancariaField } = createHelpers({
    getterType: "getCuentaBancariaField",
    mutationType: "updateCuentaBancariaField"
});
export default {
    namespaced: true,
    state: {
        cuentaBancaria: null,
        tabla: {
            items: [],
            fields: [{ key: "id" }],
            rows: [],
            isBusy: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50]
        }
    },
    mutations: {
        updateCuentaBancariaField,
    },
    actions: {
        getLista(ctx, query) {
            console.log('cuentabancariamodule.getLista', ctx);
            return CuentaBancariaSvc.getLista({
                search: query
            });
        },
        crear(ctx, data) {
            console.log("cuentabancariamodule.crear", data);
            return CuentaBancariaSvc.crear(data).then(r => {
                //ctx.commit("setAlumno", r.data.objeto);
                console.log('result crear', r.data.objeto);
                return r.data;
            });
        },
    },
    getters: {
        getCuentaBancariaField
    }
};