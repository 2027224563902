import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VerCupones from '../views/VerCupones.vue'
import VerCuponesPagados from '../views/VerCuponesPagados.vue'
import VerCuponesPendientes from '../views/VerCuponesPendientes.vue'
import RegistrarPago from '../views/RegistrarPago.vue'
import InformarProblema from '../views/InformarProblema.vue'
import BuscarAlumno from '../views/BuscarAlumno.vue'
import FormaPago from '../views/FormaPago.vue'
import store from "../store/"
import Login from '../views/auth/Login';
import Perfil from '../views/auth/Perfil';
import Registrar from '../views/auth/Registrar';
import VerificarEmail from '../views/auth/VerificarEmail';

Vue.use(VueRouter);

const requireAuthenticated = (to, from, next) => {
  store.dispatch('auth/initialize')
    .then(r => {
      console.log('end initialize', r, store.getters['auth/isAuthenticated']);
      if (!store.getters['auth/isAuthenticated']) {
        next('/login');
      } else {
        next();
      }
    });
};
// const requireUnauthenticated = (to, from, next) => {
//   store.dispatch('auth/initialize')
//     .then(() => {
//       if (store.getters['auth/isAuthenticated']) {
//         next('/home');
//       } else {
//         next();
//       }
//     });
// };
const redirectLogout = (to, from, next) => {
  store.dispatch('auth/logout')
    .then(() => next('/login'));
};

const routes = [
  {
    path: '/perfil',
    component: Perfil,
    beforeEnter: requireAuthenticated,
  },
  {
    path: '/login',
    component: Login,
    //beforeEnter: requireUnauthenticated,
  },
  {
    path: '/signup',
    component: Registrar,
    //beforeEnter: requireUnauthenticated,
  },
  {
    path: '/signup/:key',
    component: VerificarEmail,
  },
  {
    path: '/logout',
    beforeEnter: redirectLogout,
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/buscaralumno',
    name: 'Buscar Alumno',
    component: BuscarAlumno
  },
  {
    path: '/registrarpago',
    name: 'Registrar Pago',
    component: RegistrarPago,
    beforeEnter(to, from, next) {
      if(store.state.AlumnoModule.alumno === null){
        console.log(store.state.AlumnoModule.alumno, to, from, next);
        next('/buscaralumno');
      }
      next();
    }
  },
  {
    path: '/vercuponespendientes',
    name: 'Ver Cupones Pendientes',
    alias: '/vercuponespendientes',
    component: VerCuponesPendientes,
    beforeEnter(to, from, next) {
      if(store.state.AlumnoModule.alumno === null){
        console.log(store.state.AlumnoModule.alumno, to, from, next);
        next('/buscaralumno');
      }
      next();
    }
  },
  {
    path: '/vercuponespagados',
    name: 'Ver Cupones Pagados',
    alias: '/vercuponespagados',
    component: VerCuponesPagados,
    beforeEnter(to, from, next) {
      if(store.state.AlumnoModule.alumno === null){
        console.log(store.state.AlumnoModule.alumno, to, from, next);
        next('/buscaralumno');
      }
      next();
    }
  },
  
  {
    path: '/vercupones',
    name: 'Ver Cupones Adeudados',
    alias: '/vercupones',
    component: VerCupones,
    beforeEnter(to, from, next) {
      if(store.state.AlumnoModule.alumno === null){
        console.log(store.state.AlumnoModule.alumno, to, from, next);
        next('/buscaralumno');
      }
      next();
    }
  },
  {
    path: '/formapago',
    name: 'Forma de Pago',
    component: FormaPago,
    beforeEnter(to, from, next) {
      if(store.state.AlumnoModule.alumno === null){
        console.log(store.state.AlumnoModule.alumno, to, from, next);
        next('/buscaralumno');
      }
      if(store.state.PagoModule.cuponesseleccionados.length === 0){
        console.log(store.state.PagoModule.cuponesseleccionados, to, from, next);
        next('/vercupones');
      }
      next();
    }
  },
  {
    path: '/informar-problema',
    name: 'Informar un problema',
    component: InformarProblema,
    beforeEnter(to, from, next) {
      if(store.state.AlumnoModule.alumno === null){
        console.log(store.state.AlumnoModule.alumno, to, from, next);
        next('/buscaralumno');
      }
      next();
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/About.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
