import axios from "axios";
import api from "@/api";
const path = "/TransaccionBancaria";

export default {
    getLista(obj) {
        return axios.get(`${api.alumnos + path}/GetLista`, obj);
    },
    crear(obj) {
        return axios.post(`${api.alumnos + path}/Crear`, obj);
    }
};