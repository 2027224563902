import NivelSvc from "../services/nivel";

import { createHelpers } from "vuex-map-fields";

const { getNivelField, updateNivelField } = createHelpers({
    getterType: "getNivelField",
    mutationType: "updateNivelField"
});
export default {
    namespaced: true,
    state: {
        nivel:null,
        tabla: {
            items: [],
            fields: [{ key: "id" }],
            rows: [],
            isBusy: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50]
        }
    },
    mutations: {
        updateNivelField,

        setTablaRows(state, data) {
            console.log("mutation.setTabla", data);
            state.tabla.rows = data.rows;
            state.tabla.totalRows = data.total;
        }
    },
    actions: {
        getLista(ctx, obj) {
            return NivelSvc.getLista(obj);
        },
        getEstablecimientos(ctx, unidadAdministrativa_Id) {
            return NivelSvc.getEstablecimientos(unidadAdministrativa_Id);
        }
    },
    getters: {
        getNivelField
    }
};