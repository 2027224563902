<template>
  <div>
    <b-card header-tag="header" class="mt-4">
      <h4>
        <img :src="logo" />
        <router-link to="/formapago" v-slot="{href, route, navigate}"  >
            <b-button :href="href" variant="light"  @click="navigate" class='mr-2'>
              Cambiar Forma de Pago
            </b-button>
        </router-link>
      </h4>
      <b-tabs card>
        <b-tab title="Pago con boletas">
          <table-cupones :columnas="['ConceptoString','Id','Estado','UltimoVencimiento','ImporteVencimiento']" :selectable="false" selectmode="single" :listacupones="this.cuponesseleccionados">
          </table-cupones>
          <div class="text-center">
            <b-button  :href="this.urlpdfcupones" target="_blank">
                <b-icon icon="download" aria-hidden="true"></b-icon> Descargar cupones de pago
            </b-button>
          </div>
        </b-tab>
        <b-tab title="Pagar sin boleta" active>
          <b-card-text>
            <p>1. Diríjase a una sucursal de Caja Municipal y realice estos pasos</p>
            <ul>
                <li>Indique que el pago es para {{this.alumno.DisplayNivelEnseñanzaActualSinCuise}}</li>
                <li>Indique que el código de alumno es {{this.alumno.DisplayCodigoAlumnoNivel}}</li>
                <li>Verifique con el cajero el nombre del alumno: {{this.alumno.DisplayName}}</li>
                <li>Verifique con el cajero el nombre del colegio: {{this.alumno.DisplayNivelEnseñanzaActualSinCuise}}</li>
                <li>Indique el concepto y el importe a pagar.</li>
            </ul>
            <div class="text-center">
              <b-button  :href="this.urlpdfcodigo" target="_blank">
                  <b-icon icon="download" aria-hidden="true"></b-icon> Descargar Instrucciones
              </b-button>
            </div>
          </b-card-text>
        </b-tab>
      </b-tabs>
      <slot></slot>  
    </b-card>
  </div>
</template>
<script>
import api from "@/api";
import TableCupones from '@/components/TableCupones.vue';
import { createHelpers } from "vuex-map-fields";
const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});
export default {
  name: "CardCajaMunicipal",
  props: {
      logo: String
  },
  watch: {

  },
  computed: {
    ...mapFieldsAlumno(['alumno', 'idNivel']),
    ...mapFieldsPago(['formapago','importe','cuponesseleccionados']),
    urlpdfcodigo(){
      console.log(this.alumno);
      if(this.alumno){
        return `${api.alumnos}/cupon/pdfcodigoalumno?id=${this.alumno.Id}&idNivel=${this.idNivel}&formapago=0&codigoalumno=${this.alumno.DisplayCodigoAlumnoNivel}`;
      }
      return "#";
    },
    urlpdfcupones(){
      console.log(this.alumno);
      if(this.alumno){
        var cuponselected = "";
        this.cuponesseleccionados.forEach( function(valor) {
            cuponselected = cuponselected + `&cuponselected=${valor.Id}`;
        });
        console.log(this.alumno);
        return `${api.alumnos}/cupon/pdfcuponesalumno?lugarpago=cm&formato=uncuerpo&idNivel=${this.idNivel}${cuponselected}`;
      }
      return "#";
    }
  },
  components: {
    TableCupones,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>
