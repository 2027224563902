import session from '@/services/session';
import api from "@/api";
//var api = 'http://autogestion.api.saed.digital'
export default {
  login(username, password) {
    console.log('login', username);
    return session.post(api.auth + '/rest-auth/login/', { username, password });
  },
  logout() {
    return session.post(api.auth + '/rest-auth/logout/', {});
  },
  createAccount(username, password1, password2, email) {
    return session.post(api.auth + '/rest-auth/registration/', { username, password1, password2, email });
  },
  changeAccountPassword(password1, password2) {
    return session.post(api.auth + '/rest-auth/password/change/', { password1, password2 });
  },
  sendAccountPasswordResetEmail(email) {
    return session.post(api + '/rest-auth/password/reset/', { email });
  },
  resetAccountPassword(uid, token, new_password1, new_password2) { // eslint-disable-line camelcase
    return session.post(api.auth + '/rest-auth/password/reset/confirm/', { uid, token, new_password1, new_password2 });
  },
  getAccountDetails(key) {
    return session.get(api.auth + '/rest-auth/user/',
    {
        headers: { 'Authorization': "Token " + key }
    });
  },
  updateAccountDetails(data) {
    return session.patch(api.auth + '/rest-auth/user/', data);
  },
  verifyAccountEmail(key) {
    return session.post(api.auth + '/rest-auth/registration/verify-email/', { key });
  },
};