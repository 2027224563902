<template>
  <div>
    <router-link to="/vercupones" v-slot="{href, route, navigate}"  >
        <b-button size="sm" :href="href" variant="info"  @click="navigate" class='mr-2'>
          {{ getIsMobile ?  "Adeudados" : route.name }}
        </b-button>
    </router-link>
    <router-link to="/vercuponespagados" v-slot="{href, route, navigate}" >
        <b-button size="sm" :href="href" variant="success"  @click="navigate" class='mr-2'>
          {{ getIsMobile ?  "Pagados" : route.name }}
        </b-button>
    </router-link>
    <router-link to="/vercuponespendientes" v-slot="{href, route, navigate}"  >
        <b-button size="sm" :href="href" variant="warning"  @click="navigate" class='mr-2'>
          {{ getIsMobile ?  "Pendientes" : route.name }}
        </b-button>
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "BotonListaCupones",
  watch: {

  },
  computed: {
    ...mapGetters(["getIsMobile"]),
  },
  components: {
  },
  data() {
    return {
    };
  },
  methods: {

  },
  mounted() {
  }
}
</script>