<template>
  <div>
    <b-card header-tag="header" class="mt-4">
      <h4>
        <img :src="logo" />
        <router-link to="/formapago" v-slot="{href, route, navigate}"  >
            <b-button :href="href" variant="light"  @click="navigate" class='mr-2'>
              Cambiar Forma de Pago
            </b-button>
        </router-link>
      </h4>
      <hr>
      <slot></slot> 
      <form-mercado-pago></form-mercado-pago> 
    </b-card>
  </div>
</template>
<script>
import FormMercadoPago from '@/components/forms/FormMercadoPago.vue';

export default {
  name: "CardMercadoPago",
  props: {
      logo: String
  },
  watch: {

  },
  computed: {
  },
  components: {
    FormMercadoPago
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
}
</script>
