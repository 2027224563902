import { createHelpers } from "vuex-map-fields";
import CuponSvc from "../services/cupon";

const { getCuponField, updateCuponField } = createHelpers({
    getterType: "getCuponField",
    mutationType: "updateCuponField"
});
export default {
    namespaced: true,
    state: {
        cupon: null,
        tabla: {
            items: [],
            fields: [{ key: "id" }],
            rows: [],
            isBusy: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50]
        }
    },
    mutations: {
        updateCuponField,
    },
    actions: {
        getLista(ctx, obj) {
            console.log('cuponmodule.getLista', ctx);
            return CuponSvc.getLista(obj);
        },
        guardarTransferencia(ctx, data) {
            console.log("cuponmodule.guardarTransferencia", data);
            return CuponSvc.guardarTransferencia(data).then(r => {
                //ctx.commit("setAlumno", r.data.objeto);
                console.log('result guardarTransferencia', r);
                if(r.data){
                    return r.data;
                }
                return r;
            }).catch(err => {
                console.log('error GuardarTransferencia', err);
                throw new Error('No se pudo guardar la transferencia');
            });
        },
        crear(ctx, data) {
            console.log("cuponmodule.crear", data);
            return CuponSvc.crear(data).then(r => {
                //ctx.commit("setAlumno", r.data.objeto);
                console.log('result crear', r);
            });
        },
    },
    getters: {
        getCuponField
    }
};