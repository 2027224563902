import Vue from 'vue'
import './registerServiceWorker'
import { BootstrapVue, BootstrapVueIcons }  from "bootstrap-vue";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import VueLoading from "vue-loading-overlay";
import 'vue-loading-overlay/dist/vue-loading.css';
import VueToasted from "vue-toasted";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { ValidationObserver,extend, ValidationProvider, localize } from 'vee-validate';
import * as rules from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import VueSwal from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(vueNumeralFilterInstaller, { locale: 'es' });
Vue.use(require('vue-moment'));
Vue.use(VueSwal);

var pjson = require('../package.json');

console.log('package.json: ', pjson.version);
console.log(`Vue version : ${Vue.version}`);

Object.keys(rules).forEach(rule => {
  console.log(rule);
  extend(rule, rules[rule]);
});
localize("es", es);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);


Vue.config.productionTip = false;
console.log('devtools', process.env.VUE_APP_DEVTOOLS);
Vue.config.devtools =  process.env.VUE_APP_DEVTOOLS;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)

Vue.component("multiselect", Multiselect);

Vue.use(VueLoading, {
  // props
  color: 'blue',
  //text: "Procesando consulta"
},{
  // slots
})
Vue.use(VueToasted, {
  // One of the options
  position: 'top-right',
});

Vue.use(VueReCaptcha, { 
  siteKey: process.env.VUE_APP_PUBLIC_KEY,
  loaderOptions: {
    explicitRenderParameters:{
      badge:'bottomleft'
    }
  } 
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('es-ar', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2
  });
  return formatter.format(value);
});
