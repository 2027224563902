import api from "@/api";
import axios from "axios";
import qs from "qs";
import upload from "../upload"


const path = "/Cupon";
export default {
    find(obj) {
        return axios.post(`${api.alumnos + path}/Find`, qs.stringify(obj));
    },
    getLista(obj) {
        return axios.get(`${api.alumnos + path}/GetLista`, { params: obj });
    },
    postTransferencia(urlPost){
        return axios.post(urlPost).catch(err => {
            console.log('error GuardarTransferencia', err);
            throw new Error('No se pudo guardar la transferencia');
        });
    },
    guardarTransferencia(obj) {
        console.log('guardarTransferencia', obj.uploadfile, obj);
        let urlPost = `${api.alumnos + path}/GuardarTransferencia?appversion=${obj.appVersion}&grecaptcha=${obj.grecaptcha}&transaccionbancaria_id=${obj.transaccionbancaria_id}&idNivel=${obj.idNivel}${obj.cuponselected}`;
        if(obj.uploadfile){
            return upload.upload(obj.formdata).then(result => {
                console.log('end upload', result);
                urlPost = urlPost + `&filename=${result.data.FileName}`;
                console.log('postTransferencia', urlPost);
                return this.postTransferencia(urlPost);
            }).catch(err => {
                console.log('error upload', err);
                throw new Error('No se pudo subir la imagen del comprobante');
            });
        }else{
            return this.postTransferencia(urlPost);
        }
    }
};