<template>
  <div>
    <b-container fluid="md">
      <b-row>
        <b-col coll="12" lg="12">
          <b-jumbotron class="d-flex">
            <div class="aviso">
              <div class="d-flex align-items-center">
                <div class="bg-logo"></div>
                <h1 class="display-3">SAED</h1>
              </div>

              <p class="lead">
                Consulta de aportes y aviso de pagos.
              </p>

              <hr class="my-4 w-75">

              <b-button variant="info" to="/buscaralumno">Buscar Alumno</b-button>
            </div>
            <div class="video">
              <h6 class="display-5 d-flex">
                <div>
                  Hacé click en <span title="Lista de reproducción" class="material-icons">playlist_play</span> para ver todos los videos disponibles sobre el uso de Autogestión, y ver cómo realizar e informar pagos
                </div>
              </h6>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/videoseries?list=PLiaUatziuByTsmWp5lVtKhyehi1-K7C4N" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </div>
          </b-jumbotron>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>

<style>
  .aviso {
    flex-basis: 50%;
  }
  .bg-logo {
    background-image: url('../assets/logoSaed.svg');
    height: 160px;
    min-width: 145px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
  }
  @media (max-width: 991px) {
    .jumbotron.d-flex {
      flex-direction: column;
    }
    .video {
      margin-top: 2rem;
      text-align: center;
    }
  }
</style>
