<template>
  <div id="app">
    <Header />
    <router-view/>
  </div>
</template>
<script>
import axios from "axios";
import api from "@/api";
import swalerts from "@/helpers/swalerts";
import Header from "@/components/Header.vue";
import { mapGetters } from "vuex";
export default {
  mixins: [swalerts],
  computed: {
    ...mapGetters(["getIsMobile"]),
    appVersion: function(){
      return process.env.VUE_APP_VERSION;
    },
  },
  components: {
    Header
  },
  methods:{
    onResize(event) {
      console.log('window has been resized', event);
      if (screen.width <= 760) {
        this.$store.commit('setIsMobile', true);
      } else {
        this.$store.commit('setIsMobile', false);
      }
    },
    checkVersion(){
      axios.get(`${api.alumnos}/app/GetVersion`, { params: null }).then(r => {
        console.log('getversion', r);
        if(r.status !== 200){
          this.showErrorOnly("La aplicación no funciona correctamente");
        }
        if(r.data !== this.appVersion){
          console.log('version distinta', r, this.appVersion);
          this.showConfirmarOnly(
            "Versión desactualizada",
            `La versión ${this.appVersion} de la aplicación esta desactualizada. Debe actualizar a la versión ${r.data}`,
            "Actualizar"
          ).then(result => {
            console.log(result);
            window.location.reload(true);
          });
        }
      }).catch(e => {
          console.log(e);
          this.showErrorOnly("La aplicación no funciona correctamente");
      });
    }
  },
  beforeMount(){
    this.checkVersion();
    this.onResize();
  },
  mounted() {
    // Register an event listener when the Vue component is ready
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize);
  }
};
</script>
<style>


</style>
