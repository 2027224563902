var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mt-4",attrs:{"bg-variant":"light","header":"Datos del Alumno","header-tag":"header"}},[_c('h5',[_vm._v(" "+_vm._s(_vm.alumno.DisplayNivelEnseñanzaSinCuise))]),_c('h2',[_vm._v(_vm._s(_vm.alumno.DisplayName))]),_c('h5',[_vm._v("Código de alumno: "+_vm._s(_vm.alumno.DisplayCodigoAlumnoNivel))]),_c('h5',[_vm._v("Código Link Pagos: "+_vm._s(_vm.alumno.DisplayCodigoLinkPagosNivel))]),(this.unidadadministrativa_id === '3')?_c('h5',[_vm._v("Código Roela Siro: "+_vm._s(_vm.alumno.DisplayCodigoAlumnoRoela))]):_vm._e(),_c('h5',[_vm._v(_vm._s(this.displayDebitoDirecto))]),_c('h5',[_vm._v(_vm._s(!this.tienematricula ? "No tiene matricula asignada para el ciclo lectivo " + this.ciclolectivo : ""))]),_vm._t("default"),_c('router-link',{attrs:{"to":"/buscaralumno"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","href":href,"variant":"info"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Buscar" : route.name)+" ")])]}}])}),(this.tienematricula)?_c('router-link',{attrs:{"to":"/vercupones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","href":href,"variant":"info"},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.getIsMobile ? "Ver Cupones" : route.name)+" ")])]}}],null,false,2947368541)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }