export default {
methods: {
    errorHandler(e, loader) {
        loader.hide();
        console.log('errorHandler', e);
        //var statusCode = e.response !== null ? e.response.status : e;
        console.log('errorHandler.response', e.response);
        let mensaje = "Error ";
        if(e.response){
            if(e.response.data){
                console.log('errorHandler.response', e.response.data);
                mensaje = mensaje + `${e.response.status} al buscar alumno. \n ${e.response.data.status.mensaje}`;
            }
        }else{
            mensaje = mensaje + " no identificado";
        }
    
        this.$toasted.error(mensaje, {
            theme: 'bubble',
            fullWidth: true,
            // you can pass a single action as below
            action : {
                text : 'Cerrar',
                onClick : (e, toastObject) => {
                    toastObject.goAway(0);
                }
            },
        });
        },
}
}
